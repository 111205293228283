import React,{Fragment} from 'react';
import PropTypes from 'prop-types';

function QuestionCount(props) {
  return (
    <Fragment>
      <br></br>
      <div className="questionCount">
      Question <span>{props.counter}</span> of <span>{props.total}</span>
    </div>
    </Fragment>
    
  );
}

QuestionCount.propTypes = {
  counter: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
};

export default QuestionCount;
