import React from 'react';
import PropTypes from 'prop-types';

function Question(props) {
  return <h2 className="question"><br></br>{props.content}<br></br></h2>;
}

Question.propTypes = {
  content: PropTypes.string.isRequired
};

export default Question;
