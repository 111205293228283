var quizQuestions = [
  {
      question: "Category Choice click to remove as an option",
      answers: [
          {
              type: "Implant",
              content: "Implant"
          },
          {
              type: "Lift",
              content: "Lift"
          },
          {
              type: "Removal",
              content: "Removal"
          },
          {
            type: "Fat Transfer",
            content: "Fat Transfer"
        },
            {
            type: "Nipple Inversion",
            content: "Nipple Inversion"
        }
      ]
  },
  {
    question: "Category Choice click to remove as an option",
    answers: [
        {
            type: "Implant",
            content: "Implant"
        },
        {
            type: "Lift",
            content: "Lift"
        },
        {
            type: "Removal",
            content: "Removal"
        },
        {
          type: "Fat Transfer",
          content: "Fat Transfer"
      },
          {
          type: "Nipple Inversion",
          content: "Nipple Inversion"
      }
    ]
},
{
    question: "Category Choice click to remove as an option",
    answers: [
        {
            type: "Implant",
            content: "Implant"
        },
        {
            type: "Lift",
            content: "Lift"
        },
        {
            type: "Removal",
            content: "Removal"
        },
        {
          type: "Fat Transfer",
          content: "Fat Transfer"
      },
          {
          type: "Nipple Inversion",
          content: "Nipple Inversion"
      }
    ]
},{
    question: "Category Choice click to remove as an option",
    answers:[
        {
            type: "Implant",
            content: "Implant"
        },
        {
            type: "Lift",
            content: "Lift"
        },
        {
            type: "Removal",
            content: "Removal"
        },
        {
          type: "Fat Transfer",
          content: "Fat Transfer"
      },
          {
          type: "Nipple Inversion",
          content: "Nipple Inversion"
      }
    ]
}
];

export default quizQuestions;
